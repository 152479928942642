<footer class="footer">
    <div class="footer-content justify-content-between">
        <p class="m-b-0">
          Copyright © 2020 - {{ date | date:'y' }} by NetBrick - IT Solutions. All rights reserved.<br>
          <small>Version {{ version }} | {{ commitHash }}</small>
        </p>
        <span>
            <a href="https://hausmeisterapp.com/impressum/" target="_blank" class="text-gray m-r-15">Impressum</a>
            <a href="https://hausmeisterapp.com/datenschutz/" target="_blank" class="text-gray">Datenschutz</a>
        </span>
    </div>
</footer>
