import { Component } from '@angular/core';
import {commitHash, version} from '../../../../environments/version';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})

export class FooterComponent {

  public date = new Date();

  protected readonly version = version;
  protected readonly commitHash = commitHash;
}
